<template>
    <div class="contact-us">
        <h2>Contact Us</h2>

        <form @submit.prevent="submitContactForm" class="contact-form">
            <div class="form-group">
                <label for="name">Name</label>
                <input type="text" id="name" v-model="name" required />
            </div>

            <div class="form-group">
                <label for="email">Email</label>
                <input type="email" id="email" v-model="email" required />
            </div>

            <div class="form-group">
                <label for="message">Message</label>
                <textarea id="message" v-model="message" rows="4" required></textarea>
            </div>

            <!-- reCAPTCHA -->
            <!-- <div class="g-recaptcha" :data-sitekey="siteKey"></div> -->

            <div class="form-actions">
                <button type="submit" class="submit-btn">Submit</button>
            </div>
        </form>
    </div>
</template>

<script>
import axios from 'axios';
import { getCustomHeaderName, getCustomHeaderValue } from '@/utils/utils';

export default {
    name: "ContactUs",
    data() {
        return {
            name: '',
            email: '',
            message: '',
            //siteKey: process.env.VITE_RECAPTCHA_SITE_KEY, // Store your site key in environment variables
            recaptchaResponse: ''
        }
    },
    methods: {
        // Function to handle form submission
        async submitContactForm() {
            try {
                //const recaptchaResponse = grecaptcha.getResponse(); // Get reCAPTCHA response

                // Validate reCAPTCHA response
                // if (!recaptchaResponse) {
                //     alert('Please verify that you are not a robot!');
                //     return;
                // }

                const apiUrl = import.meta.env.VITE_API_URL;
                const contactUrl = `${apiUrl}/Contact/ContactUs`;
                const headerName = getCustomHeaderName();
                const headerValue = getCustomHeaderValue();

                // Prepare form data
                const contactUsForm = {
                    name: this.name,
                    email: this.email,
                    message: this.message,
                    //recaptchaResponse // Include reCAPTCHA response in the request
                };

                // Send POST request
                const response = await axios.post(contactUrl, contactUsForm, {
                    headers: {
                        [headerName]: headerValue
                    }
                });

                console.log('Contact form submitted successfully:', response.data);
                alert('Thank you for your message! We will get back to you soon.');
                // Clear form fields after successful submission
                this.name = '';
                this.email = '';
                this.message = '';
                //grecaptcha.reset(); // Reset reCAPTCHA widget
            } catch (error) {
                console.error('Error submitting contact form:', error);
                alert('There was an error submitting your form. Please try again later.');
            }
        }
    },
    mounted() {
        // Load Google reCAPTCHA script dynamically
        const script = document.createElement('script');
        script.src = `https://www.google.com/recaptcha/api.js?render=explicit`;
        script.async = true;
        script.defer = true;
        document.head.appendChild(script);
    }
}
</script>

<style scoped>
.contact-us {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

h2 {
    text-align: center;
    font-size: 24px;
    margin-bottom: 20px;
}

.contact-form {
    display: flex;
    flex-direction: column;
}

.form-group {
    margin-bottom: 20px;
}

label {
    font-weight: bold;
    margin-bottom: 8px;
    display: block;
}

input[type="text"],
input[type="email"],
textarea {
    width: 100%;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
}

textarea {
    resize: vertical;
}

.g-recaptcha {
    margin: 20px 0;
}

.form-actions {
    display: flex;
    justify-content: center;
}

.submit-btn {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.submit-btn:hover {
    background-color: #0056b3;
}
</style>