<template>
    <div class="modal-overlay" @click.self="close">
        <div class="modal-content">
            <h2 class="modal-title">Donate to Project: {{ project.name }}</h2>
            <p class="modal-description">Please enter the amount you wish to donate:</p>
            <input type="number" v-model="amount" placeholder="Amount" class="amount-input" />
            <div class="donation-option">
                <label class="donate-label">
                    Would you like to donate 1 Algo to keep our site alive?
                    <input type="checkbox" v-model="donateToUs" class="donate-checkbox" />
                </label>
            </div>
            <div class="modal-buttons">
                <button @click="submitDonation" class="submit-button">Submit</button>
                <button @click="close" class="cancel-button">Cancel</button>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import { getCustomHeaderName, getCustomHeaderValue } from '@/utils/utils';
import algosdk from 'algosdk';

export default {
    props: {
        show: {
            type: Boolean,
            required: true
        },
        project: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            amount: 0,
            donateToUs: false
        };
    },
    computed: {
        ...mapGetters(['getAccountAddress']), // Wallet address from Vuex or state
        ...mapGetters(['getToken']),
        ...mapGetters(['getPeraWallet']),
    },
    methods: {
        close() {
            this.$emit('close');
        },
        async submitDonation() {
            try {
                const apiUrl = import.meta.env.VITE_API_URL;
                let headerName = getCustomHeaderName();
                let headerValue = getCustomHeaderValue();

                const token = this.getToken;
                if (!token) {
                    throw new Error('User not authenticated.');
                }

                const senderWalletAddress = this.getAccountAddress;

                // Send data to backend to get the full payment details
                const paymentDetails = {
                    senderWalletAddress,
                    projectId: this.project.id,
                    amount: this.amount,
                    donateToUs: this.donateToUs
                };

                const response = await axios.post(apiUrl + '/Payments/GetPaymentDetails', paymentDetails, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        WalletAddress: senderWalletAddress,
                        [headerName]: headerValue
                    }
                });

                const fullPaymentDetails = response.data;
                if (fullPaymentDetails) {
                    console.log('Full payment details: ', fullPaymentDetails);

                    console.log("creating algosdk client");
                    const algod = new algosdk.Algodv2(fullPaymentDetails.algoApiToken, fullPaymentDetails.algoApiUrl, 443);
                    const status = await algod.status().do();
                    console.log('Node status:', status);
                    const txGroups = []; // To hold all transaction groups

                    // First transaction group
                    console.log("Generating txns");
                    const txn1 = await this.generatePaymentTxns(algod, fullPaymentDetails.receiverWalletAddress, fullPaymentDetails.senderWalletAddress, fullPaymentDetails.amount, 'Donation to Project on Algo.Vote');
                    console.log("first txn: " + txn1);
                    txGroups.push(...txn1);  // Add the transaction group to txGroups

                    // Optional second transaction group (if user wants to donate to you)
                    if (fullPaymentDetails.donateToUs) {
                        const txn2 = await this.generatePaymentTxns(algod, fullPaymentDetails.donationReceiverWalletAddress, fullPaymentDetails.senderWalletAddress, fullPaymentDetails.donateToUsAmount, 'Donation to Algo.Vote');
                        console.log("second txn: " + txn2);
                        txGroups.push(...txn2);  // Add the second transaction group
                    }

                    console.log(txGroups);

                    // Now sign the transactions with Pera Wallet
                    const signedTransactions = await this.signTransactionsWithPerawallet(txGroups);

                    // Submit signed transactions to the Algorand network
                    await this.submitSignedTransactions(algod, signedTransactions);

                    // Update our system with donation stats
                    await this.updateDonationInfoForProject(this.project.id, this.amount);
                } else {
                    console.log('Full payment details are null');
                    alert('There was an error processing your donation.');
                }

            } catch (error) {
                console.log('Error donating: ', error);
                alert('There was an error processing your donation.');
            }
        },
        async generatePaymentTxns(algodClient, to, initiatorAddr, amount, note) {
            // Ensure addresses are strings and correctly formatted (trim whitespace)
            if (typeof initiatorAddr !== 'string' || initiatorAddr.length !== 58) {
                throw new Error("Initiator address length is incorrect or not a valid string");
            }

            if (typeof to !== 'string' || to.length !== 58) {
                throw new Error("Recipient address length is incorrect or not a valid string");
            }

            // Trim the addresses to ensure no leading or trailing spaces
            initiatorAddr = initiatorAddr.trim();
            to = to.trim();

            // Validate both addresses
            console.log('Validating initiator address:', initiatorAddr);
            let isValidInitiator = algosdk.isValidAddress(initiatorAddr);
            if (!isValidInitiator) {
                throw new Error('Invalid initiator address');
            }

            console.log('Validating recipient address:', to);
            let isValidRecipient = algosdk.isValidAddress(to);
            if (!isValidRecipient) {
                throw new Error('Invalid recipient address');
            }

            // Get the suggested parameters for the transaction
            console.log("Getting transaction parameters...");
            const suggestedParams = await algodClient.getTransactionParams().do();
            console.log("Transaction parameters:", suggestedParams);

            // Create the payment transaction (fixed for downgraded algosdk version)
            console.log("Creating payment transaction with note: " + note);
            const txn = algosdk.makePaymentTxnWithSuggestedParamsFromObject({
                from: initiatorAddr,   // 'from' for the sender
                to: to,                // 'to' for the recipient
                amount: amount,        // Amount in microalgos
                suggestedParams: suggestedParams,
                //note: new Uint8Array(Buffer.from(note))  // Ensure the note is a Uint8Array
            });

            console.log('Created transaction:', txn);

            // Return the transaction as an array with signers
            return [{
                txn,               // The actual transaction object
                signers: [initiatorAddr] // The list of signers (initiator address)
            }];
        },
        async signTransactionsWithPerawallet(txGroups) {
            try {
                const peraWallet = this.getPeraWallet;  // Get the PeraWallet instance from Vuex

                console.log('Pera wallet in sign txn: ', peraWallet);
                console.log('Transaction Groups: ', txGroups);

                if (peraWallet) {
                    // Ensure txGroups is an array of transaction groups
                    if (!Array.isArray(txGroups) || txGroups.length === 0) {
                        throw new Error('Transaction groups are not properly formatted.');
                    }

                    // Try signing the transactions
                    const signedTransactions = await peraWallet.signTransaction([txGroups]);
                    console.log('Signed Transactions: ', signedTransactions);

                    // Return the signed transactions (make sure to handle the response format)
                    return signedTransactions;
                } else {
                    throw new Error('PeraWallet is not connected.');
                }
            } catch (error) {
                console.log('Error signing transactions with PeraWallet: ', error);
                //throw error;
            }
        },
        async submitSignedTransactions(algodClient, signedTransactions) {
            try {
                // Loop over each signed transaction group
                for (const signedTxnGroup of signedTransactions) {
                    // signedTxnGroup should contain an array of individual signed transactions
                    // We need to extract the raw transaction bytes (Uint8Array) from each txn

                    //const signedTxns = signedTxnGroup.map(txn => txn.blob); // Extract raw txn blob (Uint8Array)

                    //console.log("Submitting signed transactions:", signedTxns);
                    console.log("Submitting signed transactions:", signedTxnGroup);

                    // Send each transaction to the Algorand network
                    const { txId } = await algodClient.sendRawTransaction(signedTxnGroup).do();
                    console.log('Transaction submitted successfully with txId: ' + txId);
                }

                alert('Donation successful!');
                this.close();  // Close the modal
            } catch (error) {
                console.log('Error submitting signed transactions: ', error);
                alert('There was an error submitting your donation.');
            }
        },
        async updateDonationInfoForProject(projectId, amount) {
            const apiUrl = import.meta.env.VITE_API_URL;
            let headerName = getCustomHeaderName();
            let headerValue = getCustomHeaderValue();

            const token = this.getToken;
            if (!token) {
                throw new Error('User not authenticated.');
            }

            const senderWalletAddress = this.getAccountAddress;

            // Send data to backend to get the full payment details
            const paymentDetails = {
                senderWalletAddress,
                projectId,
                amount,
            };

            const response = await axios.post(apiUrl + '/Payments/UpdateDonationDetails', paymentDetails, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    WalletAddress: this.getAccountAddress,
                    [headerName]: headerValue
                }
            });

            console.log(response);
            const responseData = response.data;
            if (responseData) {
                console.log('Response data from update donation: ' + responseData);
            }
        }
    }
}
</script>

<style scoped>
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    background: white;
    padding: 24px;
    border-radius: 12px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
    max-width: 400px;
    width: 90%;
    text-align: center;
}

.modal-title {
    margin: 0 0 12px;
    font-size: 1.5em;
    color: #333;
}

.modal-description {
    margin-bottom: 16px;
    color: #555;
}

.amount-input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 6px;
    margin-bottom: 16px;
    font-size: 1em;
}

.donation-option {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 16px;
}

.donate-label {
    cursor: pointer;
    /* Indicate clickable label */
}

.donate-checkbox {
    margin-left: 5px;
}

.modal-buttons {
    display: flex;
    justify-content: space-between;
}

.submit-button,
.cancel-button {
    padding: 10px 15px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 1em;
    transition: background-color 0.3s, transform 0.2s;
}

.submit-button {
    background-color: #4CAF50;
    /* Green */
    color: white;
}

.submit-button:hover {
    background-color: #45a049;
    transform: scale(1.05);
}

.cancel-button {
    background-color: #f44336;
    /* Red */
    color: white;
}

.cancel-button:hover {
    background-color: #e53935;
    transform: scale(1.05);
}
</style>