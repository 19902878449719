import { createStore } from "vuex";

export default createStore({
  state: {
    loggedInUserAccountAddress: null,
    token: localStorage.getItem("token") || null, // Initialize from localStorage
    peraWallet: null, // Store the PeraWalletConnect instance
  },
  mutations: {
    setAccountAddress(state, address) {
      state.loggedInUserAccountAddress = address;
    },
    setPeraWallet(state, peraWallet) {
      state.peraWallet = peraWallet; // Store PeraWallet instance
    },
    clearPeraWallet(state) {
      state.peraWallet = null; // Clear PeraWallet when disconnected
    },
    SET_TOKEN(state, token) {
      state.token = token;
      localStorage.setItem("token", token);
    },
    CLEAR_TOKEN(state) {
      state.token = null;
      localStorage.removeItem("token");
    },
  },
  actions: {
    updateAccountAddress({ commit }, address) {
      commit("setAccountAddress", address);
    },
    setPeraWallet({ commit }, peraWallet) {
      commit("setPeraWallet", peraWallet);
    },
    clearPeraWallet({ commit }) {
      commit("clearPeraWallet");
    },
    setToken({ commit }, token) {
      commit("SET_TOKEN", token);
    },
    clearToken({ commit }) {
      commit("CLEAR_TOKEN");
    },
  },
  getters: {
    getAccountAddress: (state) => state.loggedInUserAccountAddress,
    getToken: (state) => state.token,
    getPeraWallet: (state) => state.peraWallet, // Getter to access PeraWallet instance
  },
});
