<template>
    <div v-if="isOpen" class="modal-overlay">
        <div class="modal">
            <h3>Confirm Deletion</h3>
            <p>Are you sure you want to delete this project?</p>
            <button @click="confirmDelete">Yes, Delete</button>
            <button @click="cancelDelete">Cancel</button>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        isOpen: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['close', 'confirm'],
    methods: {
        confirmDelete() {
            this.$emit('confirm');
            this.close();
        },
        cancelDelete() {
            this.close();
        },
        close() {
            this.$emit('close');
        },
    },
};
</script>

<style scoped>
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal {
    background: white;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
}
</style>