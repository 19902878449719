<template>
    <div>
        <h1 v-if="!this.getAccountAddress">All Projects</h1>
        <h1 v-else>My Projects</h1>
        <h3 v-if="this.projects.length == 0">No projects</h3>
        <Project v-for="singleProject in projects" :key="singleProject.id" :project="singleProject" :mode="'edit'"
            @donate="openModal(singleProject)" @projectDeleted="getProjects" />
    </div>
</template>

<script>
import axios from 'axios';
import Project from './Project.vue';
import { mapGetters } from 'vuex';
import { getCustomHeaderName, getCustomHeaderValue } from '@/utils/utils';

export default {
    name: "MyProjects",
    components: {
        Project
    },
    data() {
        return {
            projects: [],
            isModalOpen: false,
            selectedProject: null // To hold the project data for the modal
        }
    },
    computed: {
        ...mapGetters(['getAccountAddress']), // Map the getter here
        ...mapGetters(['getToken']),
    },
    methods: {
        async getProjects() {
            try {
                const apiUrl = import.meta.env.VITE_API_URL;
                let projectsUrl = apiUrl + `/Projects/${this.getAccountAddress}`;
                let headerName = getCustomHeaderName();
                let headerValue = getCustomHeaderValue();
                console.log('My Projects url: ' + projectsUrl);
                const token = this.getToken;
                console.log('My Projects token: ' + token);
                if (token) {
                    let response = await axios.get(projectsUrl, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                            WalletAddress: this.getAccountAddress,
                            [headerName]: headerValue
                        }
                    });

                    this.projects = response.data.map(element => ({
                        id: element.id,
                        catId: element.categoryId,
                        name: element.name,
                        author: element.author,
                        bioUrl: element.bioUrl,
                        description: element.description,
                        walletAddress: element.walletAddress,
                        imageUrl: element.imageUrl,
                        imageData: element.imageData ? "data:image/jpg;base64, " + element.imageData : "",
                        created: element.created,
                        numberOfDonations: element.numberOfDonations,
                        donationsReceived: element.donationsReceived
                    }));
                } else {
                    console.log('JWT Token is null, can\'t get my projects');
                }
            } catch (error) {
                console.log('Error Getting Projects: ' + error);
            }
        },
        openModal(project) {
            console.log('Donate clicked on project: ' + project.id);
            this.selectedProject = project; // Set the selected project
            this.isModalOpen = true; // Open the modal
        },
        closeModal() {
            this.isModalOpen = false; // Close the modal
            this.selectedProject = null; // Clear the selected project
        }
    },
    mounted() {
        this.getProjects();
    }
}
</script>

<style scoped></style>