<template>
  <nav class="navbar">
    <div class="nav-content">
      <RouterLink to="/" class="logo">
        <img alt="Vue logo" src="./assets/algorand_logo_mark_black.png" width="125" height="125" />
      </RouterLink>
      <span class="nav-title">Vote With Your Wallet for Your Favorite Algorand Based Creations</span>
      <div class="wallet-buttons">
        <button @click="disconnectWallet" v-if="getAccountAddress" class="wallet-button">Disconnect</button>
        <button @click="connectWallet" v-else class="wallet-button">Connect to Pera Wallet</button>
      </div>
      <div class="account-info" v-if="getAccountAddress">Connected to: {{ getAccountAddress }}</div>
    </div>
    <div class="nav-links">
      <RouterLink to="/Projects" class="nav-link">All Projects</RouterLink>
      <RouterLink :to="{ path: `/MyProjects/${getAccountAddress}` }" v-show="getAccountAddress" class="nav-link">My
        Projects</RouterLink>
      <RouterLink :to="{ path: '/AddProject/add' }" v-show="getAccountAddress" class="nav-link">Add Project</RouterLink>
      <a class="nav-link" target="_blank" href="https://governance.algorand.foundation">Governance Website</a>
    </div>
  </nav>

  <main class="projects-container">
    <RouterView />
  </main>

  <footer class="footer">
    <div class="footer-content">
      <RouterLink to="/ContactUs" class="footer-link">Contact Us</RouterLink>
      <span class="footer-text">© 2024 Algo.Vote. All Rights Reserved.</span>
    </div>
  </footer>
</template>

<script>
import { PeraWalletConnect } from '@perawallet/connect';
import { mapActions, mapGetters } from 'vuex';
import { useRouter } from 'vue-router';

const peraWallet = new PeraWalletConnect();

export default {
  name: 'App',
  setup() {
    const router = useRouter();
    return { router };
  },
  mounted() {
    this.checkSession();
  },
  computed: {
    ...mapGetters(['getAccountAddress', 'getPeraWallet']),
  },
  methods: {
    ...mapActions(['updateAccountAddress', 'setPeraWallet', 'setToken', 'clearToken']),
    async checkSession() {
      try {
        const accounts = await peraWallet.reconnectSession();
        if (accounts.length) {
          await this.updateAccountAddress(accounts[0]);
          this.$store.dispatch('setPeraWallet', peraWallet);
          peraWallet.connector.on('disconnect', this.disconnectWallet);
        }
      } catch (error) {
        if (error?.data?.type !== 'CONNECT_MODAL_CLOSED') {
          console.error('Reconnect session error:', error);
        }
      }
    },
    async connectWallet() {
      try {
        const accounts = await peraWallet.connect();
        if (accounts.length) {
          const walletAddress = accounts[0];
          this.$store.dispatch('setPeraWallet', peraWallet);
          peraWallet.connector.on('disconnect', this.disconnectWallet);
          const token = await this.authenticateWithBackend(walletAddress);
          if (token) {
            this.$store.dispatch('setToken', token);
            await this.updateAccountAddress(walletAddress);
          }
        }
      } catch (error) {
        console.error('Connect wallet error:', error);
        await this.updateAccountAddress(null);
      }
    },
    async disconnectWallet() {
      try {
        await peraWallet.disconnect();
        this.$store.dispatch('clearToken');
        this.$store.dispatch('clearPeraWallet');
        await this.updateAccountAddress(null);
      } catch (error) {
        console.error('Disconnect wallet error:', error);
        await this.updateAccountAddress(null);
      }
      this.router.push('/');
    },
    async authenticateWithBackend(walletAddress) {
      try {
        const apiUrl = import.meta.env.VITE_API_URL;
        const response = await fetch(`${apiUrl}/api/auth/login`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ address: walletAddress }),
        });
        if (!response.ok) throw new Error('Authentication failed');
        const data = await response.json();
        return data.token;
      } catch (error) {
        console.error('Backend authentication error:', error);
        return null;
      }
    },
  },
};
</script>

<style scoped>
/* Navbar Styling */
nav {
  display: flex;
  flex-direction: column;
  background-color: #f8f9fa;
  padding: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.nav-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo {
  margin-right: 20px;
}

.nav-title {
  flex-grow: 1;
  margin-left: 20px;
  font-weight: bold;
  color: #333;
}

.wallet-buttons {
  display: flex;
  gap: 10px;
}

.wallet-button {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.wallet-button:hover {
  background-color: #0056b3;
}

.nav-links {
  display: flex;
  gap: 15px;
  margin-top: 10px;
}

.nav-link {
  text-decoration: none;
  color: #007bff;
  font-weight: 500;
  transition: color 0.3s;
}

.nav-link:hover {
  color: #0056b3;
}

/* Footer Styling */
.footer {
  background-color: #343a40;
  color: white;
  padding: 20px;
  text-align: center;
  font-size: 14px;
  margin-top: 40px;
}

.footer-content {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.footer-link {
  text-decoration: none;
  color: #17a2b8;
  font-weight: 500;
  transition: color 0.3s;
}

.footer-link:hover {
  color: #0056b3;
}

.footer-text {
  color: #dcdcdc;
}
</style>